import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TenantRouteService {
  private subdomain: string;
  public modulePath: any;
  constructor() {
    this.subdomain = this.extractSubdomain();
    this.getModulePath()
  }

  private extractSubdomain(): string {
    const parts = window.location.hostname.split('.');
    if (parts.length > 1) {
      // The subdomain is the first part before the domain
      return parts[0];
    }
    return null; // No subdomain present
  }

  getSubdomain(): string {
    return this.subdomain;
  }

  getModulePath(): any {
    // Define the module path based on the subdomain
    if (this.subdomain){
      this.modulePath =  './tenant-authentication/tenant-authentication.module';
    }
  }

}

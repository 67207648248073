import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts-widget2',
  templateUrl: './charts-widget2.component.html',
})
export class ChartsWidget2Component implements OnInit {
  chartOptions: any = {};

  constructor() {}

  ngOnInit(): void {
  }
}


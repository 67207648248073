import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    {
        id: 'home',
        title: 'Home',
        translate: 'MENU.HOME',
        type: 'item',
        icon: 'home',
        url: 'home'
    },
    {
        id: 'courses',
        title: 'Courses',
        translate: 'MENU.COURSES',
        type: 'collapsible',
        children: [
            {
                id: 'create-course',
                title: 'Create Course',
                translate: 'MENU.HOME',
                type: 'item',
                icon: 'circle',
                url: 'lms/courses/create-course'
            },
            {
                id: 'categories',
                title: 'Categories',
                translate: 'MENU.HOME',
                type: 'item',
                icon: 'circle',
                url: 'lms/courses/categories'
            },
            {
                id: 'instructional-levels',
                title: 'Instructional Levels',
                translate: 'MENU.HOME',
                type: 'item',
                icon: 'circle',
                url: 'lms/courses/instructional-levels'
            },
            {
                id: 'media-library',
                title: 'Media  Library',
                translate: 'MENU.HOME',
                type: 'item',
                icon: 'circle',
                url: 'lms/courses/media-library'
            },
            {
                id: 'video-library',
                title: 'Video  Library',
                translate: 'MENU.HOME',
                type: 'item',
                icon: 'circle',
                url: 'lms/courses/video-library'
            },
            {
                id: 'course',
                title: 'Courses',
                translate: 'MENU.HOME',
                type: 'item',
                icon: 'circle',
                url: 'lms/courses/all'
            },


            
        ],
        icon: 'file-text',
    },

    {
        id: 'Products',
        title: 'Products',
        type: 'collapsible',
        children: [
            {
                id: 'add-product',
                title: 'Create Product',
                type: 'item',
                icon: 'circle',
                url: 'lms/products/create-product'
            },
            {
                id: 'categories',
                title: 'Categories',
                type: 'item',
                icon: 'circle',
                url: 'lms/products/categories'
            },
            {
                id: 'products',
                title: 'Products',
                type: 'item',
                icon: 'circle',
                url: 'lms/products/all'
            },
            
        ],
        icon: 'file-text',
    },
    {
        id: 'Packages',
        title: 'Packages',
        type: 'collapsible',
        children: [
            {
                id: 'add-package',
                title: 'Create package',
                type: 'item',
                icon: 'circle',
                url: 'lms/packages/create-package'
            },
            {
                id: 'packages',
                title: 'packages',
                type: 'item',
                icon: 'circle',
                url: 'lms/packages/all'
            },
            
        ],
        icon: 'file-text',
    }
    ,
    {
        id: 'Roles',
        title: 'Roles',
        type: 'collapsible',
        children: [
            {
                id: 'add-role',
                title: 'Create Role',
                type: 'item',
                icon: 'circle',
                url: 'lms/roles/create-role'
            },
            {
                id: 'Roles',
                title: 'Roles',
                type: 'item',
                icon: 'circle',
                url: 'lms/roles/all'
            },
            
        ],
        icon: 'file-text',
    }
    ,
    {
        id: 'Users',
        title: 'Users',
        type: 'collapsible',
        children: [
            {
                id: 'add-user',
                title: 'Create user',
                type: 'item',
                icon: 'circle',
                url: 'lms/users/create-user'
            },
            {
                id: 'Users',
                title: 'Users',
                type: 'item',
                icon: 'circle',
                url: 'lms/users/all'
            },
            
        ],
        icon: 'file-text',
    }
];

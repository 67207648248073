import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ApexModule} from "./apex/apex.module";
import {ChartjsModule} from "./chartjs/chartjs.module";
import {ChartsWidget1Component} from "./components/charts-widget1/charts-widget1.component";
import {ChartsWidget2Component} from "./components/charts-widget2/charts-widget2.component";
import {ChartsWidget3Component} from "./components/charts-widget3/charts-widget3.component";
import {ChartsWidget4Component} from "./components/charts-widget4/charts-widget4.component";
import {ChartsWidget6Component} from "./components/charts-widget6/charts-widget6.component";
import {ChartsWidget7Component} from "./components/charts-widget7/charts-widget7.component";
import {ChartsWidget8Component} from "./components/charts-widget8/charts-widget8.component";


@NgModule({
    declarations: [
        ChartsWidget1Component,
        ChartsWidget2Component,
        ChartsWidget3Component,
        ChartsWidget4Component,
        ChartsWidget6Component,
        ChartsWidget7Component,
        ChartsWidget8Component,],
    imports: [CommonModule, ApexModule, ChartjsModule],
    exports: [
        ChartsWidget1Component,
        ChartsWidget2Component,
        ChartsWidget3Component,
        ChartsWidget4Component,
        ChartsWidget6Component,
        ChartsWidget7Component,
        ChartsWidget8Component,]
})
export class ChartModule {
}

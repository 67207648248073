import {NgModule} from "@angular/core";
import {ChartModule} from "./charts-and-maps/charts/charts.module";
import {ChartsAndMapsModule} from "./charts-and-maps/charts-and-maps.module";

@NgModule({
    declarations: [],
    imports: [ChartModule, ChartsAndMapsModule],
    providers: [],
    exports: [ChartModule, ChartsAndMapsModule],
    bootstrap: []
})
export class SharedModule {
}

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {CoreCommonModule} from '@core/common.module';

import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';

import {SampleComponent} from './sample.component';
import {HomeComponent} from './home.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MatCardModule} from '@angular/material/card';
import {SharedModule} from "../../shared/shared.module";
import {CardSnippetModule} from "../../../@core/components/card-snippet/card-snippet.module";
import {ChartsModule} from "ng2-charts";
import {Ng2FlatpickrModule} from "ng2-flatpickr";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgApexchartsModule} from "ng-apexcharts";

const routes = [
    {
        path: 'sample',
        component: SampleComponent,
        data: {animation: 'sample'}
    },
    {
        path: 'home',
        component: HomeComponent,
        data: {animation: 'home'}
    }
];

@NgModule({
    declarations: [SampleComponent, HomeComponent],
    imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule, NgxChartsModule, MatCardModule, SharedModule,
        ContentHeaderModule,
        CardSnippetModule,
        ChartsModule,
        NgApexchartsModule,
        CoreCommonModule,
        NgbModule
    ],
    exports: [SampleComponent, HomeComponent]
})
export class SampleModule {
}

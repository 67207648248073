import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CoreDirectivesModule} from '@core/directives/directives';
import {CorePipesModule} from '@core/pipes/pipes.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatInputModule} from '@angular/material/input';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {CsvModule} from '@ctrl/ngx-csv';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import { TableComponent } from './components/table/table.component';
@NgModule({
    declarations:[
        TableComponent
    ],
    imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule,
        NgxPaginationModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgSelectModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        NgxUiLoaderModule,MatCardModule,
        NgxSkeletonLoaderModule,
        NgxSpinnerModule,
        MatInputModule,
        NgxDatatableModule,
        NgbDropdownModule,
        MatIconModule,
        MatMenuModule,MatFormFieldModule,MatSelectModule,
    ],
    exports:
        [CommonModule,
            FlexLayoutModule,
            FormsModule,
            ReactiveFormsModule,
            CoreDirectivesModule,
            CorePipesModule,
            NgxPaginationModule,
            MatDatepickerModule,
            MatNativeDateModule,
            NgSelectModule,
            MatCheckboxModule,
            MatDialogModule,
            MatButtonModule,
            MatProgressSpinnerModule,
            NgxUiLoaderModule,
            NgxSkeletonLoaderModule,
            NgxSpinnerModule,
            MatInputModule,MatCardModule,
            NgxDatatableModule,
            NgbDropdownModule,
            CsvModule,
            MatIconModule,
            MatMenuModule,MatFormFieldModule,MatSelectModule,
            TableComponent

        ]
})
export class CoreCommonModule {
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-charts-widget7',
    templateUrl: './charts-widget7.component.html',
})
export class ChartsWidget7Component implements OnInit {
    chartOptions: any = {};

    constructor() {
    }

    ngOnInit(): void {
    }
}


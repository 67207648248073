import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-charts-widget6',
    templateUrl: './charts-widget6.component.html',
})
export class ChartsWidget6Component implements OnInit {
    chartOptions: any = {};

    constructor() {
    }

    ngOnInit(): void {
    }
}

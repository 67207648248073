import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChartModule} from "./charts/charts.module";
import {GoogleMapModule} from "./google-maps/google-maps.module";

@NgModule({
    declarations: [],
    imports: [CommonModule, ChartModule, GoogleMapModule],
    exports: []
})
export class ChartsAndMapsModule {
}

export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      SAMPLE: 'Sample',
      COURSES: 'Courses',
    }
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-charts-widget1',
    templateUrl: './charts-widget1.component.html',
})
export class ChartsWidget1Component implements OnInit {
    chartOptions: any = {};

    constructor() {
    }

    ngOnInit(): void {
    }
}


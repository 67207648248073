import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts-widget4',
  templateUrl: './charts-widget4.component.html',
})
export class ChartsWidget4Component implements OnInit {
  chartOptions: any;

  constructor() {}

  ngOnInit(): void {
  }
}


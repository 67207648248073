import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr'; // For auth after login toast
import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';

import {coreConfig} from 'app/app-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/tenant App/sample.module';
import {ErrorInterceptor, JwtInterceptor} from './auth/helpers';

const appRoutes: Routes = [
    {
        path: 'authentication',
        loadChildren: () => import('./main/central App/modules/auth/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'chart',
        loadChildren: () => import('./shared/charts-and-maps/charts/apex/apex.module').then(m => m.ApexModule)
    }, {
        path: 'chart2',
        loadChildren: () => import('./shared/charts-and-maps/charts/chartjs/chartjs.module').then(m => m.ChartjsModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./main/tenant App/modules/modules.module').then(m => m.ModulesModule)
    }, {
        path: 'lms',
        loadChildren: () => import('./main/tenant App/modules/modules.module').then(m => m.ModulesModule)
    },
    {
        path: '',
        redirectTo: '/auth/user/login',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
    }
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled'
        }),
        TranslateModule.forRoot(),
        //NgBootstrap
        NgbModule,
        ToastrModule.forRoot(),

        // Core modules
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,

        // App modules
        LayoutModule,
        SampleModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true,},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true,}

    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
